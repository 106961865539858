import React, { useEffect, useState } from 'react';
import './Resource.css'
import { Button } from '@mui/material'
import BaseUrl from '../../../BaseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FirstResource from '../../images/Resources/Acti-Vote.png'
import SecondResource from '../../images/Resources/Out-reach Circle1.png'



function formatDateString(dateString) {
  const options = {
    // weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

function Resource() {

  const [researchList, setResearchList] = useState([]);

  const [podcastList, setPodcastList] = useState([]);

  const Navigate = useNavigate()

  useEffect(() => {

      axios
      .get(`${BaseUrl}/api/get-all-story`)
      .then((response) => {
        const formattedStories = response.data.stories.map(story => {
          return {
            ...story
          };
        });
        setResearchList(formattedStories);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });


      axios
      .get(`${BaseUrl}/api/get-all-podcast`)
      .then((response) => {
        const formattedPodcasts = response.data.podcasts.map(podcast => {
          return {
            ...podcast
          };
        });
        setPodcastList(formattedPodcasts);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });
      
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);





  return (
    <div className='landingPageResource'>

      <div className='ameenah'>
        <div></div>
        <div>
            <h1>Resources</h1>
        </div>
        <div></div>
      </div>


      <div className='seven'>
        <div className='left'>
            <img src={FirstResource} alt="" />

            <p  onClick={()=>{
              window.open('https://www.activote.net/', '_blank');
            }}>https://www.activote.net</p>

            <p>Are you ready to make a difference in your community and be a more informed voter? Look no further than Activote, the mobile application that's revolutionizing civic engagement and voter participation.</p>
        </div>

        <div className='right'>
          <img src={SecondResource} alt="" />

          <p onClick={()=>{
              window.open('https://outreachcircle.com/', '_blank');
          }}>https://outreachcircle.com</p>

          <p>Ready to make a positive impact in our community? Join the Newberry Institute's Outreach Circle and be a part of the change we all want to see. Together, we're stronger.</p>
        </div>
      </div>



      <div className='six'>
        <div>
            <h3>Featured Articles from around the Globe.</h3>
            <div>
              <p></p>
              <p onClick={()=>{
                Navigate(`/stories`)
              }}></p>
            </div>

            <div>
              {researchList && researchList.map((object, index) => (
                <div key={index} onClick={()=>{
                  Navigate(`/story/${object.id}?slug=${encodeURIComponent(object.title).replace(/%20/g, '-')}`)
                }}>
                  <img src={`${BaseUrl}/images/${object.image}`} alt={object.subtitle} />
                  <p>
                    {object.title.length > 100
                        ? object.title.slice(0, 100) + '...'
                        : object.title}
                      <p>
                    {formatDateString(object.date)}
                  </p>
                  </p>
                </div>
              ))}
            </div>


            <Button onClick={()=>{
                Navigate(`/stories`)
              }}>See all</Button>
        </div>

        <div>
          <h3>Our Podcast & Featured Youtube Videos.</h3>
          <div>
            <p></p>
            <p onClick={()=>{
                Navigate(`/podcasts`)
              }}></p>
          </div>

          <div>
            {podcastList && podcastList.map((object, index) => (
              <div key={index} onClick={()=>{
                Navigate(`/podcast/${object.id}?slug=${encodeURIComponent(object.title).replace(/%20/g, '-')}`)
              }}>
                <img src={`${BaseUrl}/images/${object.image}`} alt={object.title} />
                <p>
                  {object.title.length > 100
                        ? object.title.slice(0, 100) + '...'
                        : object.title}
                </p>
              </div>
            ))}
          </div>

          <Button onClick={()=>{
                Navigate(`/podcasts`)
              }}>See all</Button>
        </div>
      </div>

    </div>
  )
}

export default Resource
