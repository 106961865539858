import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import axiosInstance from '../../../axiosInstance';

function ShowPodcast({ handleToggle, togActive }) {
  const location = useLocation();
  const podcastId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [podcast, setPodcast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch podcast data based on the podcastId using custom Axios instance
    axiosInstance
      .get(`/api/get-single-podcast/${podcastId}`)
      .then((response) => {
        const podcastData = response.data.podcast; // Assuming your API returns podcast data in a specific format
        setPodcast(podcastData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching podcast data:', error);
        setError('An error occurred while fetching the podcast data.');
        setLoading(false);
      });
  }, [podcastId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {podcast && (
            <div>
              <h1>Podcast</h1>
              <div>
                <strong>Title:</strong> {podcast.title}
              </div>
              
              <div>
                <strong>Slug:</strong> {podcast.slug}
              </div>
             
              <div>
                <strong>Link:</strong> {podcast && <a href={podcast.link} target="_blank" rel="noopener noreferrer">listen to podcast</a>}
              </div>



              <div>
                <strong>Description:</strong> {podcast.description}
              </div>

              {podcast.image && (
                <div>
                  <strong>Image:</strong>
                  <img src={`${BaseUrl}/images/${podcast.image}`} alt="podcast pic"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                </div>
              )}
              <div>
                {/* You can display other podcast details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowPodcast;
