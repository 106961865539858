import React, {useEffect} from 'react'
import './Division.css'

import Imag from "../../images/Capital Insurrection.jpg"

import Blog1 from '../../images/plow.png'
import Blog2 from '../../images/annie.png'
import Blog3 from '../../images/rosa.jpg'

import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

function Division() {
    const Navigate = useNavigate()

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageDivision'>
      <div>
        <div>
            <p>The Newberry Institute</p>
            <h1>The Plow and Pew Democracy Center</h1>
            <p>The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research. The Reading Room is a safe space that offers an opportunity for community members to learn and explore the history, religion, peace studies, justice, and education related to the Black community. The Reading Room serves as the intellectual center of gravity of The Newberry Foundation, and it sponsors intellectual programming that covers various aspects of the Black Experience.</p>
            <p>The Reading Room is not only a library, but it's also a place where community members can gather to discuss, debate, and learn from each other. It is a hub of activity that offers a peaceful and stimulating environment to all visitors. The Reading Room is adjacent to Annie Lee's Porch, which adds to the charm of the location. Annie Lee's Porch offers a beautiful view of the surrounding area and provides a cozy and comfortable setting to relax and enjoy a cup of coffee or tea.</p>

            <Button onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
          }}>Donate</Button>
        </div>
        <div>
            <div className='huh'>
                <img src={Imag} alt="" />
            </div>
        </div>
      </div>

       <div className='child'>
            <div>
                <div>
                <img src={Blog1} alt="" />
                </div>

                <div>
                <h4>The Plow and Pew Democracy Center</h4>
                <h3 onClick={()=>{
                    Navigate(`/democratisation`)
                    window.scrollTo(0, 0)
                }}>Education is a fundamental pillar for a flourishing democracy</h3>
                <p>The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research.</p>
                </div>
            </div>

            <div>
                <div>
                <img src={Blog2} alt="" />
                </div>

                <div>
                <h4>Annie Lee's Porch</h4>
                <h3 onClick={()=>{
                    Navigate(`/culture-and-heritage`)
                    window.scrollTo(0, 0)
                }}>Preserving our heritage and providing insight into our historical experience</h3>
                <p>Nestled in the heart of an unassuming southern town, lies a hidden gem of African American history and culture.</p>
                </div>
            </div>

            <div>
                <div>
                <img src={Blog3} alt="" />
                </div>

                <div>
                <h4>Rosa's Food Pantry</h4>
                <h3 onClick={()=>{
                    Navigate(`/health-and-wellness`)
                    window.scrollTo(0, 0)
                }}>Healing our community through food, one meal at a time</h3>
                <p>Rosa's Food Pantry provides free healthy and nutritional food to our beneficiaries in Hawkinsville.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Division
