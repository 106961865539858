import React, { useState, useEffect } from 'react';
import './SliderCarousel.css';

import Image1 from '../../../images/ameenah/a1.jpeg'
import Image2 from '../../../images/ameenah/a2.jpeg'
import Image3 from '../../../images/ameenah/a3.jpeg'
import Image4 from '../../../images/ameenah/a4.jpeg'
// import { ArrowRight } from '@mui/icons-material';


const images = [
  {
    'id': 1,
    'image': Image1,
    'description': `Ameenah's Interfaith Center is a beacon of unity, where diverse faiths find harmony under one roof. Its architecture mirrors a beautiful blend of religions, creating a space for understanding and respect. Let's embrace diversity and celebrate our shared humanity.`
  },

  {
    'id': 2,
    'image': Image2,
    'description': `Step inside Ameenah's Interfaith Center and you'll discover a space filled with vibrant discussions, honest dialogues, and collaborative events. It's where the tapestry of cultural exchange is woven, and the beauty of diversity is celebrated. Let's come together and bridge divides.`
  },

  {
    'id': 3,
    'image': Image3,
    'description': `In a world often marked by divisions, Ameenah's Interfaith Center shines as a symbol of hope. Here, people of all faiths unite to celebrate their commonalities and foster understanding. Join us in promoting peace, love, and respect for one another.`
  },

  {
    'id': 4,
    'image': Image4,
    'description': `Ameenah's Interfaith Center stands tall as a symbol of unity and respect for all faiths. It's a place where differences are celebrated, and shared humanity is embraced. Come be a part of this inspiring journey towards harmony and togetherness.`
  },
];

const SliderCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);



  // const goTo = () => {
  //   if (isPaused) {
  //     setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  //   }
  // };

  useEffect(() => {
    const nextSlide = () => {
      if (!isPaused) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      }
    };
    
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 30000); // Change to 20000 for 20 seconds as per your original code

    return () => {
      clearInterval(autoSlideInterval);
    };
  }, [isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div className="banner-sliders-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="sliders">
        <div
          className="sliders-wrapper"
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {images.map((object, index) => (
            <div key={index} className="slidem">
              <img src={object.image} alt={`Slidem ${index}`} />
              <div className='descriptionHolder'>
                <p>{object.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <button className="next-button" onClick={goTo}>
        <ArrowRight />
      </button> */}
    </div>
  );
};

export default SliderCarousel;
