import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';  // Import useLocation
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './Donation.css'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function EditDonation({ handleToggle, togActive }) {

  const Navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    email: '',
    amount: ''
  });

  const location = useLocation();  // Get the location object

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    axios.get(`${BaseUrl}/api/get-single-donation/${id}`)
      .then(response => {
        setFormData(response.data.donation);
      })
      .catch(error => {
        console.error('Error fetching donation:', error);
      });
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = location.pathname.split('/').pop();
    axiosInstance.put(`${BaseUrl}/api/update-single-donation/${id}`, formData)
      .then(response => {
        Navigate('/dashboard/donations');
      })
      .catch(error => {
        console.error('Error updating donation:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='donationForm'>
          <form onSubmit={handleSubmit}>
           <h1>Edit Donation</h1>
            <div>
              <label>Email:</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Amount:</label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Update Donation</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditDonation;
