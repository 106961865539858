import React, {useEffect} from 'react'
import './Division3.css'

import Imag1 from "../../images/rosa1.jpeg"
import Imag2 from "../../images/rosa2.jpeg"
import Imag3 from "../../images/rosa3.png"
import Imag4 from "../../images/rosa4.jpeg"

import Blog1 from '../../images/plow.png'
import Blog2 from '../../images/annie.png'
import Blog3 from '../../images/rosa.jpg'

import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

function Division3() {
    const Navigate = useNavigate()

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageDivision3'>
      <div>
        <div>
            <p>The Newberry Institute</p>
            <h1>Rosa Food Pantry</h1>
            <p>Healing our community through food, one meal at a time</p>
            <p>Rosa's Food Pantry provides free healthy and nutritional food to our beneficiaries in Hawkinsville. We believe that access to nutritious meals is essential for the well-being of individuals and families. Our dedicated team works tirelessly to ensure that everyone in our community has access to the food they need to thrive.</p>
            <p>We understand the importance of making healthy choices when it comes to food. That's why Rosa's Food Pantry is committed to empowering our community with the knowledge and resources they need to make informed decisions about their diet. We offer cooking classes, recipe ideas, and nutritional education to help you and your family lead a healthier lifestyle.</p>
            <p>If your family is in need of assistance, we are here to help. Please fill out our application form, which you can find on our website. Once you've completed the application, return it to Rosa's Food Pantry. Our team will review your application and schedule future appointments for you and your loved ones accordingly. We want to ensure that everyone who requires our support receives it with dignity and respect.</p>

            <div className='locTime'>
                <div>
                    <h4>Location</h4>
                    <p>89 Commerce Street Hawkinsville</p>
                    <p>Georgia, USA</p>
                </div>

                <div>
                    <h4>Hours of Operation</h4>
                    <p>Thursdays and Friday</p>
                    <p>10:00 AM - 4:00 PM EST</p>
                </div>

                <div>
                    <h4>Contact</h4>
                    <p>+1 478-285-4685</p>
                    <p>Email: sharon@thenewberryfoundation.org</p>
                </div>
            </div>

            <Button onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
          }}>Donate</Button>
        </div>

        <div className='huh2Parent'>
            <div className='huh2'>
                <img src={Imag1} alt="" />
                <img src={Imag2} alt="" />
                <img src={Imag3} alt="" />
                <img src={Imag4} alt="" />
            </div>
        </div>
      </div>

       <div className='child'>
            <div>
                <div>
                <img src={Blog1} alt="" />
                </div>

                <div>
                <h4>The Plow and Pew Democracy Center</h4>
                <h3 onClick={()=>{
                    Navigate(`/democratisation`)
                    window.scrollTo(0, 0)
                }}>Education is a fundamental pillar for a flourishing democracy</h3>
                <p>The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research.</p>
                </div>
            </div>

            <div>
                <div>
                <img src={Blog2} alt="" />
                </div>

                <div>
                <h4>Annie Lee's Porch</h4>
                <h3 onClick={()=>{
                    Navigate(`/culture-and-heritage`)
                    window.scrollTo(0, 0)
                }}>Preserving our heritage and providing insight into our historical experience</h3>
                <p>Nestled in the heart of an unassuming southern town, lies a hidden gem of African American history and culture.</p>
                </div>
            </div>

            <div>
                <div>
                <img src={Blog3} alt="" />
                </div>

                <div>
                <h4>Rosa's Food Pantry</h4>
                <h3 onClick={()=>{
                    Navigate(`/health-and-wellness`)
                    window.scrollTo(0, 0)
                }}>Healing our community through food, one meal at a time</h3>
                <p>Rosa's Food Pantry provides free healthy and nutritional food to our beneficiaries in Hawkinsville.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Division3
