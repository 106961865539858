import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexElection({ handleToggle, togActive }) {
  const [electionList, setElectionList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch election records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-election')
      .then((response) => {
        setElectionList(response.data.elections); // Assuming your API returns an array of election records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching election records:', error);
        setLoading(false);
      });
  }, []);



  const handleDelete = (electionId) => {
    // Send a DELETE request to the API to delete the election record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-election/${electionId}`)
      .then(() => {
        // Remove the deleted election record from the state
        setElectionList((prevElectionList) =>
        prevElectionList.filter((election) => election.id !== electionId)
        );
        console.log('election record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting election record:', error);
      });
  };

  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Election calendar</h2>
              <Link to="/dashboard/create-election">Create election</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  electionList.map((election) => (
                    <tr key={election.id}>
                      <td>{election.title}</td>
                      <td>{election.date}</td>
                      <td>
                        <Link to={`/dashboard/show-election/${election.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-election/${election.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(election.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexElection;
