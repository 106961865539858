import React, {useEffect} from 'react'
import './EducationDivision.css'

import Imag1 from "../../images/plow-pew2.jpg"

import Exhibit1 from "../../images/ed1.jpg"
import Exhibit2 from "../../images/ed2.jpg"
import Exhibit3 from "../../images/ed3.jpeg"
import Exhibit4 from "../../images/ed4.jpg"

import { Button } from '@mui/material'

function EducationDivision() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageEducationDivision'>
      <div>
        <div>
            <p>
                The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research. The Reading Room is a safe space that offers an opportunity for community members to learn and explore the history, religion, peace studies, justice, and education related to the Black community. The Reading Room serves as the intellectual center of gravity of The Newberry Institute. It sponsors intellectual programming that covers various aspects of the Black experience and the democratic process.
            </p>
            <p>The Reading Room is not only a library, but it's also a place where community members can gather to discuss, debate, and learn from each other. It is a hub of activity that offers a peaceful and stimulating environment to all visitors. The Reading Room is adjacent to Annie Lee's Porch, which adds to the charm of the location. Annie Lee's Porch offers a beautiful view of the surrounding area and provides a cozy and comfortable setting to relax and enjoy a cup of coffee or tea.</p>

            <Button className='justify-center' onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
          }}>Donate</Button>
        </div>

        <div className='huh2Parent'>
            <div className='huh4'>
                <img src={Imag1} alt="" />
            </div>
        </div>
        </div>

       <div className='educationActivities'>
          <div className='educationActivitiesCover'>
            <div className='educationActivitiesCoverCardHolder'>
                <div>
                    <h2>Activities</h2>
                </div>
                <div>
                    <img src={Exhibit1} alt="" />
                </div>
                <div>
                    <h2>Educational Programs</h2>

                    <p>We conduct workshops, seminars, and training sessions to educate the public about democratic principles, civic rights, and responsibilities. We provide resources and materials to enhance political literacy and awareness.</p>
                </div>
                <div>
                    <img src={Exhibit2} alt="" />
                </div>
                <div>
                    <h2>Voter Engagement</h2>

                    <p>
                        Organizing voter registration drives to ensure maximum participation in elections. Hosting forums and debates where citizens can engage with political candidates and discuss key issues. Facilitating information campaigns to help voters make informed choices.
                    </p>
                </div>
                <div>
                    <img src={Exhibit3} alt="" />
                </div>
                <div>
                    <h2>Advocacy and Activism</h2>

                    <p>
                        Advocating for policies that strengthen democratic institutions and protect civil liberties. Supporting and organizing peaceful protests, campaigns, and advocacy efforts to address issues related to democracy and governance. Collaborating with like-minded organizations to amplify the impact of advocacy initiatives.
                    </p>
                </div>
                <div>
                    <img src={Exhibit4} alt="" />
                </div>
                <div>
                    <h2>Engage Community Building</h2>

                    <p>
                        Fostering a sense of community through events, forums, and networking opportunities. Encouraging open dialogue and discussions among diverse groups to promote understanding and cooperation. Building partnerships with local organizations to create a network of support for democratic values.
                    </p>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default EducationDivision
