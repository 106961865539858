import React from 'react'
import './LandingPage.css'
import SliderCarousel from '../SliderCarousel/SliderCarousel'
import RestOfPage from './RestOfPage'

function LandingPage() {
  return (
    <div className='landingPageContainer'>
      <SliderCarousel />
      <RestOfPage />
    </div>
  )
}

export default LandingPage
