import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import BaseUrl from '../../../BaseUrl';
import axiosInstance from '../../../axiosInstance';

function Index({ handleToggle, togActive }) {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch user records from the API using axiosInstance
    axiosInstance
      .get(`${BaseUrl}/api/get-all-users`)
      .then((response) => {
        setUserList(response.data.users);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user records:', error);
        setLoading(false);
      });
  }, []);



  const handleDelete = (userId) => {
    // Send a DELETE request to the API to delete the user record
    axiosInstance
      .delete(`${BaseUrl}/api/delete-single-user/${userId}`)
      .then(() => {
        // Remove the deleted user record from the state
        setUserList((prevUserList) =>
          prevUserList.filter((user) => user.id !== userId)
        );
        console.log('User record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting user record:', error);
      });
  };


  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Users</h2>
              <Link to="/dashboard/create-user">Create user</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  userList.map((user) => (
                    <tr key={user.id}>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>
                        <Link to={`/dashboard/show-user/${user.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-user/${user.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(user.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
