import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './User.css'
import axiosInstance from '../../../axiosInstance';

function Show({ handleToggle, togActive }) {
  const location = useLocation();
  const userId = location.pathname.split('/').pop();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch user data based on the userId
    axiosInstance
      .get(`${BaseUrl}/api/get-single-user/${userId}`)
      .then((response) => {
        const userData = response.data.user; // Assuming your API returns user data in a specific format
        setUser(userData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setError('An error occurred while fetching the user data.');
        setLoading(false);
      });
  }, [userId]);

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='createShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {user && (
            <div>
              <h1>User</h1>
              <div>
                <strong>Username:</strong> {user.username}
              </div>
              <div>
                <strong>Email:</strong> {user.email}
              </div>
              <div>
                {/* You can display other user details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Show;
