import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexGallery({ handleToggle, togActive }) {
  const [galleryList, setGalleryList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch gallery records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-gallery')
      .then((response) => {
        setGalleryList(response.data.gallery_items); // Assuming your API returns an array of gallery records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching gallery records:', error);
        setLoading(false);
      });
  }, []);

  



  const handleDelete = (galleryId) => {
    // Send a DELETE request to the API to delete the gallery record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-gallery/${galleryId}`)
      .then(() => {
        // Remove the deleted gallery record from the state
        setGalleryList((prevGalleryList) =>
          prevGalleryList.filter((gallery) => gallery.id !== galleryId)
        );
        console.log('Gallery record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting gallery record:', error);
      });
  };

  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Gallery</h2>
              <Link to="/dashboard/create-gallery">Create gallery</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                    galleryList && galleryList.map((gallery) => (
                    <tr key={gallery.id}>
                      <td>{gallery.title}</td>
                      <td>
                        <Link to={`/dashboard/show-gallery/${gallery.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-gallery/${gallery.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(gallery.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexGallery;
