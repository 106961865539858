import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
function Header({statey, handleToggle}) {
    const Navigate = useNavigate()
    useEffect(()=>{
        // add hovered class to selected list item
        let list = document.querySelectorAll(".navigation li");

        function activeLink() {
        list.forEach((item) => {
            item.classList.remove("hovered");
        });
        this.classList.add("hovered");
        }

        list.forEach((item) => item.addEventListener("mouseover", activeLink));

        // Menu Toggle
        let toggle = document.querySelector(".toggle");
        let navigation = document.querySelector(".navigation");
        let main = document.querySelector(".main");

        toggle.onClick = function () {
        navigation.classList.toggle("active");
        main.classList.toggle("active");
        };
    },[]);


    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768); // Adjust the screen size as needed
        };

        checkScreenSize(); // Check initial screen size

        // Event listener to check screen size on resize
        window.addEventListener('resize', checkScreenSize);

        return () => {
        // Cleanup: remove the event listener on component unmount
        window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const handleItemClick = () => {
        // Only trigger handleToggle if it's a small screen
        if (isSmallScreen) {
        handleToggle();
        }
    };
  return (
    <div className={`navigation ${statey && "active"}`}>
            <ul>
                <li>
                </li>

                <li>
                    <Link to="/">
                        <span className="icon">
                            <ion-icon name="home-outline"></ion-icon>
                        </span>
                        <span className="title">Home</span>
                    </Link>
                </li>


                <li onClick={handleItemClick}>
                    <Link to="/dashboard">
                        <span className="icon">
                            <ion-icon name="apps-outline"></ion-icon>
                        </span>
                        <span className="title">Dashboard</span>
                    </Link>
                </li>
                

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/researches">
                        <span className="icon">
                            <ion-icon name="search-circle-outline"></ion-icon>
                        </span>
                        <span className="title">Researches</span>
                    </Link>
                </li>

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/galleries">
                        <span className="icon">
                            <ion-icon name="images-outline"></ion-icon>
                        </span>
                        <span className="title">Gallery</span>
                    </Link>
                </li>

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/donations">
                        <span className="icon">
                            <ion-icon name="cash-outline"></ion-icon>
                        </span>
                        <span className="title">Donations</span>
                    </Link>
                </li>


                <li onClick={handleItemClick}>
                    <Link to="/dashboard/users">
                        <span className="icon">
                            <ion-icon name="people-circle-outline"></ion-icon>
                        </span>
                        <span className="title">Users</span>
                    </Link>
                </li>

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/teams">
                        <span className="icon">
                            <ion-icon name="people-circle-outline"></ion-icon>
                        </span>
                        <span className="title">Team</span>
                    </Link>
                </li>


                <li onClick={handleItemClick}>
                    <Link to="/dashboard/action-networks">
                        <span className="icon">
                            <ion-icon name="bag-check-outline"></ion-icon>
                        </span>
                        <span className="title">Actions</span>
                    </Link>
                </li>


                <li onClick={handleItemClick}>
                    <Link to="/dashboard/events">
                        <span className="icon">
                            <ion-icon name="calendar-outline"></ion-icon>
                        </span>
                        <span className="title">Events</span>
                    </Link>
                </li>

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/elections">
                        <span className="icon">
                             <ion-icon name="archive-outline"></ion-icon>
                        </span>
                        <span className="title">Election</span>
                    </Link>
                </li>

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/articles">
                        <span className="icon">
                            <ion-icon name="newspaper-outline"></ion-icon>
                        </span>
                        <span className="title">Articles</span>
                    </Link>
                </li>

                <li onClick={handleItemClick}>
                    <Link to="/dashboard/podcasts">
                        <span className="icon">
                            <ion-icon name="megaphone-outline"></ion-icon>
                        </span>
                        <span className="title">Podcasts</span>
                    </Link>
                </li>


                <li onClick={handleItemClick}>
                    <Link to="/dashboard/emails">
                        <span className="icon">
                            <ion-icon name="mail-outline"></ion-icon>
                        </span>
                        <span className="title">Email list</span>
                    </Link>
                </li>


                <li onClick={()=>{
                    localStorage.removeItem('token')
                    Navigate('/')
                }}>
                      <Link>
                        <span className="icon">
                            <ion-icon name="log-out-outline"></ion-icon>
                        </span>
                        <span className="title">Sign Out</span>
                      </Link>
                </li>
            </ul>
        </div>
  )
}

export default Header
