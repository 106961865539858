import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import axiosInstance from '../../../axiosInstance';

function ShowEvent({ handleToggle, togActive }) {
  const location = useLocation();
  const eventId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch event data based on the eventId using custom Axios instance
    axiosInstance
      .get(`/api/get-single-event/${eventId}`)
      .then((response) => {
        const eventData = response.data.event; // Assuming your API returns event data in a specific format
        setEvent(eventData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching event data:', error);
        setError('An error occurred while fetching the event data.');
        setLoading(false);
      });
  }, [eventId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {event && (
            <div>
              <h1>Event</h1>
              <div>
                <strong>Title:</strong> {event.title}
              </div>
              <div>
                <strong>Slug:</strong> {event.slug}
              </div>
              <div>
                <strong>Flag:</strong> {event.flag}
              </div>
              <div>
                <strong>Description:</strong> {event.description}
              </div>

              <div>
                <strong>Date:</strong> {event.date}
              </div>
              {event.image && (
                <div>
                  <strong>Image:</strong>
                  <img src={`${BaseUrl}/images/${event.image}`} alt="event pic"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                </div>
              )}
              <div>
                {/* You can display other event details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowEvent;
