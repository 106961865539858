import React, { useEffect, useState } from 'react';
import './Podcast.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BaseUrl from '../../../BaseUrl';



function Podcast() {
  const Navigate = useNavigate();
  const { id } = useParams();

  const [podcastList, setPodcastList] = useState([]);
  const [image, setImage] = useState(null)

  const [podcast, setPodcast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch podcast data based on the id
    axios
      .get(`${BaseUrl}/api/get-single-podcast/${id}`)
      .then((response) => {
       
        const podcastData = response.data.podcast; // Assuming your API returns podcast data in a specific format
        setPodcast(podcastData);
        setImage(`${BaseUrl}/images/${podcastData.image}`)
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching podcast data:', error);
        setError('An error occurred while fetching the podcast data.');
        setLoading(false);
      });



      axios
      .get(`${BaseUrl}/api/get-all-podcast`)
      .then((response) => {
        const formattedPodcasts = response.data.podcasts.map(podcast => {
          return {
            ...podcast
          };
        });
        setPodcastList(formattedPodcasts);
      })
      .catch((error) => {
        console.error('Error fetching podcast records:', error);
      });

      window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className='landingPageTroublePodcasts'>
      <div>
        <div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {podcast && (
          <div>
            <h2>
              {podcast.title}
            </h2>
            <p>
              {podcast.description}
            </p>
            {podcast.link && <a href={podcast.link} target="_blank" rel="noopener noreferrer">Listen to podcast</a>}
          </div>
        )}
        </div>

        <div className='huh'>
          <img src={image} alt='' />
        </div>
      </div>


      <div className='child'>
        {podcastList && podcastList.map((object, index) => (
            <div key={index} onClick={() => {
              Navigate(`/podcast/${object.id}`);
              window.scrollTo(0, 0);
            }}>
              <div>
                <img src={`${BaseUrl}/images/${object.image}`} alt='' />
              </div>

              <div>
                <h4 style={{color: '#f5ffff'}}>
                  {object.title.length > 50
                        ? object.title.slice(0, 50) + '...'
                        : object.title}
                </h4>
                <p>
                  {object.description.length > 100
                        ? object.description.slice(0, 100) + '...'
                        : object.description}
                </p>
                {object.link && <a href={object.link} target="_blank" rel="noopener noreferrer">Listen to podcast</a>}
              </div>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default Podcast;
