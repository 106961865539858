import React, { useState } from 'react';
import './Login.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import BaseUrl from '../../../BaseUrl';
import Swal from 'sweetalert2';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    
    let formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    try {
      const response = await axios.post(`${BaseUrl}/api/login`, formData)


      if(response.status === 200) {
        // Assuming your backend returns a token upon successful login
        const { token } = response.data;
        if(token) {

          // Store the token in localStorage or a secure storage mechanism of your choice
          localStorage.setItem('token', token);

          // Navigate to the dashboard after successful login
          navigate('/dashboard');
        }

      } 
     
    } catch (error) {
      // Handle login failure
      Swal.fire({
        title: 'Error',
        text: 'Invalid credentials',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className='landingPageLogin'>
      <form onSubmit={handleLogin}>
        <label>Login</label>
        <input
          type='text'
          placeholder='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <Button type='submit'>Submit</Button>
      </form>
    </div>
  );
}

export default Login;
