import React, { useState } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { useNavigate } from 'react-router-dom';
import './Team.css'
import axiosInstance from '../../../axiosInstance';

function CreateTeam({ handleToggle, togActive }) {
  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    image: null,
    name: '',
    type: '',
    position: '',
    workstation: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('position', formData.position);
    formDataToSend.append('workstation', formData.workstation);
  
    // Send the form data using Axios
    axiosInstance
      .post('/api/add-team', formDataToSend)
      .then((response) => {
        // Handle success, e.g., redirect to a success page or show a success message
        Navigate('/dashboard/teams');
      })
      .catch((error) => {
        // Handle error, e.g., show an error message to the user
        console.error('Error creating Team:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='teamForm'>
          
          <form onSubmit={handleSubmit}>
           <h1>Create Team</h1>
            <div>
              <label>Image:</label>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
            </div>

            <div>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Position:</label>
              <input
                type="text"
                name="position"
                value={formData.position}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Workstation:</label>
              <input
                type="text"
                name="workstation"
                value={formData.workstation}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Team Type:</label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Team Type</option>
                <option value="director">Director</option>
                <option value="staff">Staff</option>
                <option value="advisory">Advisory</option>
              </select>
            </div>

            <button type="submit">Create Team</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateTeam;
