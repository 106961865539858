import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import axiosInstance from '../../../axiosInstance';

function ShowActionNetwork({ handleToggle, togActive }) {
  const location = useLocation();
  const actionNetworkId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [actionNetwork, setActionNetwork] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch actionNetwork data based on the actionNetworkId using custom Axios instance
    axiosInstance
      .get(`/api/get-single-action-network/${actionNetworkId}`)
      .then((response) => {
        const actionNetworkData = response.data.actionNetwork; // Assuming your API returns actionNetwork data in a specific format
        setActionNetwork(actionNetworkData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching actionNetwork data:', error);
        setError('An error occurred while fetching the actionNetwork data.');
        setLoading(false);
      });
  }, [actionNetworkId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {actionNetwork && (
            <div>
              <h1>Action network</h1>
              <div>
                <strong>Title:</strong> {actionNetwork.title}
              </div>
              <div>
                <strong>Slug:</strong> {actionNetwork.slug}
              </div>
              <div>
                <strong>Description:</strong> {actionNetwork.description}
              </div>

              <div>
                <strong>Link:</strong> {actionNetwork.link}
              </div>
              {actionNetwork.image && (
                <div>
                  <strong>Image:</strong>
                  <img src={`${BaseUrl}/images/${actionNetwork.image}`} alt="actionNetwork pic"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                </div>
              )}
              <div>
                {/* You can display other actionNetwork details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowActionNetwork;
