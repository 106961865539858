import React, {useEffect} from 'react'
import './WhatWeDo.css'
import Vid from '../../video/Newberry Institute.mp4'
import Imag1 from '../../images/annie.png'
import Imag2 from '../../images/rosa.jpg'
import Imag3 from '../../images/plow.png'
import Imag4 from '../../images/synagogue.jpg'
import { useNavigate } from 'react-router-dom'

function WhatWeDo() {

  const Navigate = useNavigate()
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className='landingPageWhatWeDo'>

      <div className='one'>
        <video autoPlay muted loop>
          <source src={Vid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <h2>What we do</h2>

      <div className='text-card'>
        <p className='text-border'>
          Strengthening Democracy through community-based initiatives is our passion at The Newberry Institute.
          <br></br>
          Drawing from the unique experiences and lessons learned from African American history we're making a lasting impact. Join us in shaping a more inclusive and equitable future.
        </p>
      </div>

      <div className='two'>
        <h2>Programs</h2>
        <div>
          <div onClick={()=>{
            Navigate(`/the-plow-and-pew-democracy-center`)
          }} style={{cursor: 'pointer'}}>
            <img src={Imag3} alt="" />
            <h2>The Plow & Pew Democracy Center</h2>
          </div>
         <div onClick={()=>{
            Navigate(`/rosa's-food-pantry`)
          }} style={{cursor: 'pointer'}}>
            <img src={Imag2} alt="" />
            <h2>Rosa's Food Pantry</h2>
          </div>
          <div onClick={() => {
            Navigate(`/aniee-lee's-porch`)
          }} style={{cursor: 'pointer'}}>
            <img src={Imag1} alt="" />
            <h2>Annie Lee's Porch</h2>
          </div>
          <div onClick={()=>{
            Navigate(`/ameenah's-interfaith-center`)
          }} style={{cursor: 'pointer'}}>
            <img src={Imag4} alt="" />
            <h2>Ameenah's Interfaith Center</h2>
          </div>
        </div>

      </div>

      <div className='three'>
        <h2>Get involved</h2>
        <p>The Newberry Institute's work upholds the idea that Democracy is a human right – a principle enshrined in the Universal Declaration of Human Rights. But it's a human right that many around the world still struggle to attain. You can be part of the solution. Join the movement for Democracy.</p>
      </div>
    </div>
  )
}

export default WhatWeDo
