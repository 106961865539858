import React from 'react'
import Customer1 from '../images/log.png'

function DashboardTopBar({handleToggle}) {
  return (
    <div className="topbar">
        <div className="toggle" onClick={handleToggle}>
            <ion-icon name="menu-outline"></ion-icon>
        </div>


        <div className="user">
            <img src={Customer1} alt="" />
        </div>
    </div>
  )
}

export default DashboardTopBar
