import React, { useEffect, useState } from 'react';
import './AllEvents.css'
import { Button } from '@mui/material'
import BaseUrl from '../../../BaseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function formatDate(dateString) {
  const options = {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
  };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}



function AllEvents() {
  const [eventList, setEventList] = useState([]);

  const [latestEventList, setLatestEventList] = useState([]);
  

  const Navigate = useNavigate()

  useEffect(() => {
    axios
    .get(`${BaseUrl}/api/get-all-event`)
    .then((response) => {
      const events = response.data.events;

      // Sort events by date in descending order
      const sortedEvent = events.sort((a, b) => new Date(a.date) - new Date(b.date));


      // Format the events
      const formattedEvents = sortedEvent.map(event => {
        return {
          id: event.id,
          title: event.title,
          start: event.date, // Assuming start is a date string in ISO format
          end: event.date,   // Assuming end is a date string in ISO format
          tooltip: event.title,
          desc: event.description
        };
      });


      setEventList(formattedEvents);
    })
    .catch((error) => {
      console.error('Error fetching research records:', error);
    });



      axios
      .get(`${BaseUrl}/api/get-all-event`)
      .then((response) => {
        const events = response.data.events;

        if (events.length > 0) {
          events.sort((a, b) => new Date(a.date) - new Date(b.date));
          const latestEvent = events[0];

          const formattedEvent = {
            id: latestEvent.id,
            title: latestEvent.title,
            start: latestEvent.date,
            end: latestEvent.date,
            tooltip: latestEvent.title,
            desc: latestEvent.description
          };


          

          setLatestEventList([formattedEvent]);
        } else {
          setLatestEventList([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });
      
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);





  return (
    <div className='landingBoxRestOfBox'>


      <div className='ameenah'>
        <div></div>
        <div>
            <h1>Attend an event</h1>
        </div>
        <div></div>
      </div>
    

      <div className='four'>


        <div>
          <div className='actionNetwork'>
            <h2 style={{color: '#f5ffff'}}>Join our action network</h2>

            <Button onClick={()=>{
              Navigate('/action-network')
            }}>Visit</Button>
          </div>
          
          <div>
            {latestEventList && latestEventList.map((latestEventList, index)=>{
              return(
                <div key={index} onClick={()=>{
                  Navigate(`/event/${latestEventList.id}?slug=${encodeURIComponent(latestEventList.title).replace(/%20/g, '-')}`)
                }}>
                  <div>
                    <h4 style={{color: '#f5ffff'}}>{formatDate(latestEventList.start)}</h4>
                  </div>
                  <div>
                    <p>
                      {latestEventList.desc.length > 100
                          ? latestEventList.desc.slice(0, 100) + '...'
                          : latestEventList.desc}
                    </p>
                    <h4 style={{color: '#f5ffff'}}>
                      {latestEventList.title.length > 100
                          ? latestEventList.title.slice(0, 100) + '...'
                          : latestEventList.title}
                    </h4>
                    <p>{formatDate(latestEventList.start)}</p>
                  </div>
                </div>
              )
            })}
            

            <div>
            {eventList && eventList.slice(1).map((eventItem, index) => (
                <div key={index} onClick={() => {
                  Navigate(`/event/${eventItem.id}?slug=${encodeURIComponent(eventItem.title).replace(/%20/g, '-')}`)
                }}>
                  <div>
                    <h4 style={{color: '#f5ffff'}}>{formatDate(eventItem.start)}</h4>
                  </div>
                  <div>
                    <p>
                      {eventItem.desc.length > 30
                        ? eventItem.desc.slice(0, 30) + '...'
                        : eventItem.desc}
                    </p>
                    <h4 style={{color: '#f5ffff'}}>
                      {eventItem.title.length > 100
                        ? eventItem.title.slice(0, 100) + '...'
                        : eventItem.title}
                    </h4>
                    <p>{formatDate(eventItem.start)}</p>
                  </div>
                </div>
            ))}
              
            </div>
          </div>

          {eventList.length > 3 && <Button className='eventButtonClass' onClick={()=>{
            Navigate('/events')
          }}>See all event</Button>}
          
        </div>
      </div>


    </div>
  )
}

export default AllEvents