import React, { useEffect } from 'react';
import './Donate.css';
import { Check } from '@mui/icons-material';

function Donate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Function to handle donation completion

  return (
    <div className="landingPageDonateMain">
      <div className="landingPageDonate">
        <h2>Donate</h2>
        <p>
          Join us in making a difference! Be a catalyst for positive change by donating to
          The NEWBERRY INSTITUTE.

          Together, we can create a brighter future for all.
          Every donation counts and brings us one step closer to our mission.
          Support The NEWBERRY INSTITUTE and be a superhero for good.
          Join the movement for change and donate now.
        </p>

        <div className='involved'>
            <div>
                <h4 style={{color: '#f5ffff'}}>Become one of our partners or donors</h4>

                <p>
                    Remember, the power to make a difference lies within each one of us.
                    Together, let's create a brighter future for all.
                </p>

                <p>Some of the Causes we are involved in</p>

                <div className='involvedType'>
                    <div>
                        <p><Check /> Health and Wellness</p>
                        <p><Check /> Community Development</p>
                    </div>
                    <div>
                        <p><Check /> Education</p>
                        <p><Check /> Promoting Interfaith Dialogue</p>
                    </div>
                </div>
            </div>

            <div>
                <p>Your donation will directly impact the lives of individuals and families who rely on our organization's services.</p>

                <div>
                  <div>
                  {/* PayPal button */}
                    <a href="https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG" target="_blank" rel="noopener noreferrer" className='payPalButton'>
                      Donate
                    </a>
                  </div>
                </div>

                <p>*All donations are tax inclusive</p>
            </div>
        </div>

      </div>
    </div>
  );
}

export default Donate;
