import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import axiosInstance from '../../../axiosInstance';

function ShowElection({ handleToggle, togActive }) {
  const location = useLocation();
  const electionId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [election, setElection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch election data based on the electionId using custom Axios instance
    axiosInstance
      .get(`/api/get-single-election/${electionId}`)
      .then((response) => {
        const electionData = response.data.election; // Assuming your API returns election data in a specific format
        setElection(electionData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching election data:', error);
        setError('An error occurred while fetching the election data.');
        setLoading(false);
      });
  }, [electionId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {election && (
            <div>
              <h1>election</h1>
              <div>
                <strong>Title:</strong> {election.title}
              </div>
              <div>
                <strong>Slug:</strong> {election.slug}
              </div>
              <div>
                <strong>Description:</strong> {election.description}
              </div>

              <div>
                <strong>Date:</strong> {election.date}
              </div>
              {election.image && (
                <div>
                  <strong>Image:</strong>
                  <img src={`${BaseUrl}/images/${election.image}`} alt="election pic"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                </div>
              )}
              <div>
                {/* You can display other election details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowElection;
