import React, {useEffect} from 'react'
import './WhereWeWork2.css'
import Imag from '../../images/hawk.jpeg'

import Partner1 from '../../images/partner1.png'
import Partner2 from '../../images/partner2.png'
import Partner3 from '../../images/partner3.png'
import Partner4 from '../../images/partner4.png'


function WhereWeWork() {
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className='landingPageWhereWeWorkMain'>
        <div className='first'>
            <img src={Imag} alt="" />
      </div>
      

      <div className='second '>
        <h2>How we Work</h2>

        <p className='text-border text-card'>
          The Newberry Institute is a catalyst for Democracy. We collaborate with the Center for Common Ground, Federal, State, and Local Institutions through Grants, Cooperative agreements, Donations and MOUs.
          <br></br>
          Together we're empowering rural communities and building a stronger Democracy. Join us on this transformative journey.
        </p>
        </div>

        <h2>Partners</h2>

        <div className='third'>
          <div>
            <img src={Partner1} alt="" />
          </div>
          <div>
            <img src={Partner2} alt="" />
          </div>
          <div>
            <img src={Partner3} alt="" />
          </div>
          <div>
            <img src={Partner4} alt="" />
          </div>
        </div>

    </div>
  )
}

export default WhereWeWork
