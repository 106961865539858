import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './Gallery.css'
import axiosInstance from '../../../axiosInstance';

function ShowGallery({ handleToggle, togActive }) {
  const location = useLocation();
  const galleryId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch gallery data based on the galleryId
    axiosInstance
      .get(`/api/get-single-gallery/${galleryId}`)
      .then((response) => {
        const galleryData = response.data.gallery_item;
        setGallery(galleryData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching gallery data:', error);
        setError('An error occurred while fetching the gallery data.');
        setLoading(false);
      });
  }, [galleryId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='galleryShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {gallery && (
            <div>
              <h1>Show Gallery</h1>
              <div>
                <strong>Title:</strong> {gallery.title}
              </div>
              <div>
                <strong>Slug:</strong> {gallery.slug}
              </div>
              {gallery.image && (
                <div>
                  <strong>Image:</strong>
                  <img
                    src={`${BaseUrl}/images/${gallery.image}`}
                    alt="gallery pic"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  />
                </div>
              )}
              <div>
                {/* You can display other gallery details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowGallery
