import React, {useEffect} from 'react'
import './Interfaith.css'
import Imag from '../../images/ameenah/a1.jpeg'
import { Button } from '@mui/material'
import InterfaithDivision from './InterfaithDivision'
// import Banner from '../../Banner/Banner'
import Banner1 from './Banner/Banner1'

function Interfaith() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <>
      <div className='landingPageInterfaith'>
        <div className='one'>
            <img src={Imag} alt="" />
        </div>

        <div className='two'>
          <div>
              <h1>Ameenah's Interfaith Center</h1>
              <p>Ameenah's Interfaith Center will stand as a symbol of unity welcoming people of all faiths under one roof. Its architectural design reflects the harmonious blend of various religious influences, creating a space that we hope to foster understanding and respect. Inside, we envision vibrant discussions, honest dialogues, and collaborative events to weave a rich tapestry of cultural exchange and promote a sense of shared humanity</p>

              {/* <div className='newberryInterfaithSign'>it
                  <video
                          autoPlay
                          muted
                          controls={true}
                          loop={true}
                          // Add other attributes like src, width, height, etc. as needed
                      >
                          <source src={Video} type="video/mp4" />
                          Your browser does not support the video tag.
                  </video>
              </div> */}

                  <p>Kindly donate to make this a reality for the betterment of our community</p>
                  <p>In a world often divided by differences, this Center will serve as a beacon of hope, where individuals from different walks of faith can come together to celebrate their commonalities and appreciate the beauty of diversity.</p>

              <Button onClick={()=>{
                window.open('https://www.gofundme.com/f/ameenahs-interfaith-center', '_blank');
                        }}>Donate</Button>
                        
          </div>

         </div>


      <Banner1 />

      <div className='blenderCardHolder'>
        <iframe
            title="Blade 3D Viewer"
            src="https://teliportme.com/view/2159118/543ebbd6-b88d-4999-9eff-1e486176e28c-panorama"
            width="100%"
            height="500px" // Adjust the height as needed
            frameBorder="0"
        ></iframe>
      </div>

    </div>

    <InterfaithDivision />
    </>
  )
}

export default Interfaith
