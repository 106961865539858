import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexArticle({ handleToggle, togActive }) {
  const [articleList, setArticleList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch article records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-article')
      .then((response) => {
        setArticleList(response.data.articles); // Assuming your API returns an array of article records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching article records:', error);
        setLoading(false);
      });
  }, []);



  const handleDelete = (articleId) => {
    // Send a DELETE request to the API to delete the article record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-article/${articleId}`)
      .then(() => {
        // Remove the deleted article record from the state
        setArticleList((prevArticleList) =>
          prevArticleList.filter((article) => article.id !== articleId)
        );
        console.log('article record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting article record:', error);
      });
  };

  
  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Articles</h2>
              <Link to="/dashboard/create-article">Create article</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  articleList?.map((article) => (
                    <tr key={article.id}>
                      <td>{article.title}</td>
                      <td>
                        <Link to={`/dashboard/show-article/${article.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-article/${article.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(article.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexArticle;
