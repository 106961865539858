import React, { useEffect, useState } from 'react';
import './AllStories.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import BaseUrl from '../../../BaseUrl';
import axios from 'axios';

function formatDate(dateString) {
  const options = { weekday: 'long', day: 'numeric', year: 'numeric', month: 'long' };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}


function AllArticles() {
  const Navigate = useNavigate();

  const [researchList, setResearchList] = useState([]);

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/get-all-article`)
      .then((response) => {
        const formattedArticles = response?.data?.articles?.map(article => {
          return {
            ...article,
            formattedDate: formatDate(article.created_at) // Add a formattedDate property
          };
        });
        setResearchList(formattedArticles);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isBodyExpanded, setIsBodyExpanded] = useState(false);

  const handleReadMore = (body, title) => {
    Navigate(`/article/${body}?slug=${encodeURIComponent(title).replace(/%20/g, '-')}`)
    setIsBodyExpanded(true);
  };

  return (
    <div className='landingPageAllStories'>
      {researchList && researchList.map((object, index) => (
        <div className='whitespace-pre-line' key={index}>
          <div>
            <h1>{object.title}</h1>
            <p>{object.subtitle}</p>
            <p>{object.formattedDate}</p>
            {isBodyExpanded === index ? (
              <p className='whitespace-pre-line'>{object.body}</p>
            ) : (
              <p>
                {object?.body.length > 300
                  ? object.body.slice(0, 300) + '...'
                  : object.body}
              </p>
            )}
            {object.body.length > 300 && (
              <Button onClick={() => handleReadMore(object.id, object.subtitle)}>
                Read more
              </Button>
            )}
          </div>
          <div>
            <div className='huh huho'>
              {object.pdf &&
                <iframe
                    src={`${BaseUrl}/pdfs/${object.pdf}`}
                    title="Article PDF"
                    style={{ width: '100%', height: '350px', border: 'none' }}
                  />
                }
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AllArticles;
