import React from 'react';
import './Banner.css';
import BannerVideo from '../video/interfaith.mp4';
import { Button } from '@mui/material';
import SliderCarousel from './SliderCarousel';
//


function Banner() {

  return (
    <div className='landingPageBanner'>   
      <div className='parent'>
        <div className='node'>
          <video
            autoPlay
            muted
            controls={true}
            loop={true}
            // Add other attributes like src, width, height, etc. as needed
          >
            <source src={BannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className='node'>
          <SliderCarousel />
        </div>
      </div>

      <Button onClick={() => 
          {
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
          }
        } className='landingPageBannerDonateButton'>
          Donate
      </Button>
    </div>
  );
}

export default Banner;
