import React, { useState, useEffect } from 'react';
import './Action.css';
import axios from 'axios';
import BaseUrl from '../../../BaseUrl';

function Action() {
  const [actionNetwork, setActionNetwork] = useState([]);

  useEffect(() => {
    axios
    .get(`${BaseUrl}/api/get-all-action-network`)
    .then((response) => {
      setActionNetwork(response.data.actionNetworks);
    })
    .catch((error) => {
      console.error('Error fetching action network records:', error);
    });

    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='landingPageAction'>
      
      {actionNetwork && actionNetwork.map((object, index) => (
        <div key={index}>
          <div>
            <h1>{object.title}</h1>
            <p>{object.description}</p>
            <div>
                <strong>Link:</strong> <a href={object.link} target="_blank" rel="noopener noreferrer">{object.link}</a>
              </div>
          </div>
          <div>
            <div className='huh'>
              <img src={`${BaseUrl}/images/${object.image}`} alt='' />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Action;
