import React, {useEffect} from 'react'
import './Division2.css'

import Imag1 from "../../images/annie1.jpg"
import Imag2 from "../../images/annie2.jpg"
import Imag3 from "../../images/annie3.jpg"
import Imag4 from "../../images/annie4.jpg"

import Blog1 from '../../images/plow.png'
import Blog2 from '../../images/annie.png'
import Blog3 from '../../images/rosa.jpg'

import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

function Division2() {
    const Navigate = useNavigate()

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageDivision2'>
      <div>
        <div>
            <p>The Newberry Institute</p>
            <h1>Annie Lee's Porch</h1>
            <p>Nestled in the heart of an unassuming southern town, lies a hidden gem of African American history and culture.</p>
            <p>The Annie Lee's Porch African American Heritage Center is a tribute to the legacy and contributions of Black Americans.</p>
            <p>The center is dedicated to preserving and showcasing the rich history and traditions of the African American community. With a collection of historical artifacts, educational exhibits, and rare photographs, the center offers visitors a unique opportunity to learn about the Black experience and to gain a deeper understanding and appreciation of African American culture.</p>

            <div className='locTime'>
                <div>
                    <h4>Location</h4>
                    <p>89 Commerce Street Hawkinsville</p>
                    <p>Georgia, USA</p>
                </div>

                <div>
                    <h4>Hours of Operation</h4>
                    <p>Thursdays and Friday</p>
                    <p>10:00 AM - 5:00 PM EST</p>
                </div>

                <div>
                    <h4>Contact</h4>
                    <p>+1 478 982 333</p>
                    <p>Email: info@thenewberryinstitutes.org</p>
                </div>

                <div>
                    <h4>Group Tours / Admission</h4>
                    <p>We offer discounts for seniors, students and groups.</p>
                    <p>We encourage visitors to book in advance to ensure availability.</p>
                </div>
            </div>

            <Button onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
          }}>Donate</Button>
        </div>

        <div className='huh2Parent'>
            <div className='huh2'>
                <img src={Imag1} alt="" />
                <img src={Imag2} alt="" />
                <img src={Imag3} alt="" />
                <img src={Imag4} alt="" />
            </div>
        </div>
      </div>

       <div className='child'>
            <div>
                <div>
                <img src={Blog1} alt="" />
                </div>

                <div>
                <h4>The Plow and Pew Democracy Center</h4>
                <h3 onClick={()=>{
                    Navigate(`/democratisation`)
                    window.scrollTo(0, 0)
                }}>Education is a fundamental pillar for a flourishing democracy</h3>
                <p>The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research.</p>
                </div>
            </div>

            <div>
                <div>
                <img src={Blog2} alt="" />
                </div>

                <div>
                <h4>Annie Lee's Porch</h4>
                <h3 onClick={()=>{
                    Navigate(`/culture-and-heritage`)
                    window.scrollTo(0, 0)
                }}>Preserving our heritage and providing insight into our historical experience</h3>
                <p>Nestled in the heart of an unassuming southern town, lies a hidden gem of African American history and culture.</p>
                </div>
            </div>

            <div>
                <div>
                <img src={Blog3} alt="" />
                </div>

                <div>
                <h4>Rosa's Food Pantry</h4>
                <h3 onClick={()=>{
                    Navigate(`/health-and-wellness`)
                    window.scrollTo(0, 0)
                }}>Healing our community through food, one meal at a time</h3>
                <p>Rosa's Food Pantry provides free healthy and nutritional food to our beneficiaries in Hawkinsville.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Division2
