import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link, useNavigate } from 'react-router-dom';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import axiosInstance from '../../../axiosInstance';

function IndexEmail({ handleToggle, togActive }) {
    const Navigate = useNavigate()
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API
    axiosInstance
      .get(`/api/get-all-email`)
      .then(response => {
        setEmails(response.data.emails); // Assuming your API returns an array of email objects
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching emails:', error);
        setLoading(false);
      });
  }, []);
  

  const handleDelete = (emailId) => {
    // Send a DELETE request to the API to delete the email
    axiosInstance
      .delete(`/api/delete-single-email/${emailId}`)
      .then(response => {
        // Remove the deleted email from the state
        setEmails(prevEmails => prevEmails.filter(email => email.id !== emailId));
        console.log('email deleted successfully:', response.data);
      })
      .catch(error => {
        console.error('Error deleting email:', error);
      });
  };


  return (
    <div className="container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="cardBox">
          {/* ... */}
        </div>

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Email list</h2>

              <Link to="/dashboard/create-email">
                Create email
              </Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="2">Loading...</td>
                  </tr>
                ) : (
                  emails.map(email => (
                    <tr key={email.id}>
                      <td>{email.email}</td>
                      <td><RemoveRedEyeOutlined onClick={()=>{
                        Navigate(`/dashboard/show-email/${email.id}`)
                      }} /> </td>
                      <td><Edit onClick={()=>{
                        Navigate(`/dashboard/edit-email/${email.id}`)
                      }} /> </td>
                      <td>
                        <button onClick={() => handleDelete(email.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexEmail;
