import React, {useEffect} from 'react'
import './InterfaithDivision.css'

import Exhibit1 from "../../images/drjsa.jpeg"
import Exhibit2 from "../../images/int2.png"
import Exhibit3 from "../../images/interfaithpeeps.jpeg"
import Exhibit4 from "../../images/int4.jpg"


function InterfaithDivision() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageInterfaithDivision'>

       <div className='interfaithActivities'>
          <div className='interfaithActivitiesCover'>
            <div className='interfaithActivitiesCoverCardHolder'>
                <div>
                    <h2>Activities</h2>
                </div>
                <div>
                    <img src={Exhibit1} alt="" />
                </div>
                <div>
                    <h2>Interfaith Dialogues</h2>

                    <p>We conduct workshops, seminars and training sessions to educate the public about Interfaith activities,initiatives and programs that enhance democracy. Our dialogues find similarities and common purpose between faith traditions.</p>
                </div>
                <div>
                    <img src={Exhibit2} alt="" />
                </div>
                <div>
                    <h2>Multi-faith Worship Services</h2>

                    <p>
                        Ameenah's Interfaith center will host inclusive worship services that draw inspiration from different religious traditions. These services will include readings, prayers, or rituals from various faiths, creating a shared spiritual experience.
                    </p>
                </div>
                <div>
                    <img src={Exhibit3} alt="" />
                </div>
                <div>
                    <h2>Educational Workshops</h2>

                    <p>
                        We will have workshops on topics like religious literacy, cultural awareness, and interfaith understanding to provide participants with a deeper understanding of different faiths. We hope that these educational initiatives contribute to breaking down stereotypes and fostering a sense of unity.
                    </p>
                </div>
                <div>
                    <img src={Exhibit4} alt="" />
                </div>
                <div>
                    <h2>Community Service Projects</h2>

                    <p>
                        We will engage in service projects that address common social issues and allow people of different faiths to come together for a shared purpose. This will include initiatives such as feeding the homeless, environmental conservation efforts, or supporting local charities in collaboration with our other Centers.
                    </p>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default InterfaithDivision
