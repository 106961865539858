import React, { useState, useEffect } from 'react';
import './Home.css';
import DashboardTopBar from '../Layouts/DashboardTopBar';
import BaseUrl from '../../BaseUrl';
import axios from 'axios';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';

function Home({ handleToggle, togActive }) {
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAmountDay, setTotalAmountDay] = useState(null)
  const [totalAmountMonth, setTotalAmountMonth] = useState(null)
  const [totalAmountYear, setTotalAmountYear] = useState(null)

  const Navigate = useNavigate()

  useEffect(() => {
    // Fetch data from the API
    axios.get(`${BaseUrl}/api/get-all-donation`)
      .then(response => {
        setDonations(response.data.donations);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching donations:', error);
        setLoading(false);
      });


      axios.get(`${BaseUrl}/api/total-amount`)
      .then(response => {
        setTotalAmountDay(response.data.total_amount_current_date);
        setTotalAmountMonth(response.data.total_amount_current_month);
        setTotalAmountYear(response.data.total_amount_current_year);
      })
      .catch(error => {
        console.error('Error getting total amount:', error);
      });
  }, []);

  const handleDelete = (donationId) => {
    axios.delete(`${BaseUrl}/api/delete-single-donation/${donationId}`)
      .then(response => {
        setDonations(prevDonations => prevDonations.filter(donation => donation.id !== donationId));
        console.log('Donation deleted successfully:', response.data);
      })
      .catch(error => {
        console.error('Error deleting donation:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="cardBox">
          <div className="card">
            <div>
              <div className="numbers">${totalAmountDay}</div>
              <div className="cardName">Todays donations</div>
            </div>

            <div className="iconBx">
              <ion-icon name="cash-outline"></ion-icon>
            </div>
          </div>


          <div className="card">
            <div>
              <div className="numbers">${totalAmountMonth}</div>
              <div className="cardName">This month's donations</div>
            </div>

            <div className="iconBx">
              <ion-icon name="cash-outline"></ion-icon>
            </div>
          </div>


          <div className="card">
            <div>
              <div className="numbers">${totalAmountYear}</div>
              <div className="cardName">This year's donations</div>
            </div>

            <div className="iconBx">
              <ion-icon name="cash-outline"></ion-icon>
            </div>
          </div>

          <div className="card">
            <div>
              <div className="numbers">The Newberry Institute</div>
            </div>
          </div>
        </div>

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Recent donations</h2>

              <Link to="/dashboard/create-donation">
                Create donation
              </Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3">Loading...</td>
                  </tr>
                ) : (
                  donations.map(donation => (
                    <tr key={donation.id} style={{gap: '20px'}}>
                      <td>{donation.email}</td>
                      <td>{donation.amount}</td>
                      <td style={{gap: "20px", display: 'flex', alignItems: 'center'}}>
                        <RemoveRedEyeOutlined onClick={()=>{
                        Navigate(`/dashboard/show-donation/${donation.id}`)
                      }} />
                        <Edit onClick={()=>{
                        Navigate(`/dashboard/edit-donation/${donation.id}`)
                      }} />
                        <button onClick={() => handleDelete(donation.id)}>Delete</button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
