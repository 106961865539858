import React, {useState, useEffect, useCallback} from 'react'
import './Navbar.css'
import TopNavbar from './TopNavbar/TopNavbar'
import MiddleNavbar from './MiddleNavbar/MiddleNavbar'
import BottomNavbar from './BottomNavbar/BottomNavbar'
import Mobile from './MiddleNavbar/Mobile'

function Navbar() {

  const [togActive2, setTogActive2] = useState(false);

  const [isFixed, setIsFixed] = useState(false);
  const handleToggle2 = () => {
    setTogActive2(!togActive2);
  };

  const handleScroll = useCallback(() => {
    if (window.scrollY > 50 && !isFixed) {
      setIsFixed(true);
    } else if (window.scrollY <= 50 && isFixed) {
      setIsFixed(false);
    }
  }, [isFixed]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={`landingNavbarContainer ${isFixed ? 'fixed' : ''}`}>
      <TopNavbar />
      <MiddleNavbar handleToggle2={handleToggle2} togActive2={togActive2} />
      <BottomNavbar />
      <Mobile handleToggle2={handleToggle2} togActive2={togActive2} />
    </div>
  )
}

export default Navbar
