import React from 'react';
import './WhoWeAre.css';
// import axiosInstance from '../../../axiosInstance';
// import Board from '../../images/board.jpg';
import Board1 from '../../images/board1.jpeg';
import BoardI from '../../images/logo.png'
import Staff from '../../images/staff.jpg';
import Advisory from '../../images/volunteers.jpg';
import { useNavigate } from 'react-router-dom';

function WhoWeAre() {
  const Navigate = useNavigate();

  return (

    <div className='landingPageWhoWeAre'>
      <div className='landingPageWhoWeAreBanner'>
        <img src={Board1} alt="" />
      </div>


      <div className='second'>
        <div>
          <h3>
            Meet the Team
          </h3>
        </div>
        <div>
          <div>
            <img src={BoardI} alt="Board members" />

            <h3>Board of Directors</h3>

            <p>Discover The Newberry Institute, led by Chairman of the Board, Dr. Julius Johnson in partnership with Board of Directors.</p>

            <p onClick={()=>{
              Navigate(`/team-type/director`)
            }}>View our Board of Directors</p>
          </div>


          <div>
            <img src={Advisory} alt="advisory members" />

            <h3>Advisory Board</h3>

            <p>Guiding excellence through empowering Programs. Our Advisory Board's vision in action upholds our reputation for excellence and ensures success in our mission.</p>

            <p onClick={()=>{
              Navigate(`/team-type/advisory`)
            }}>Meet our Advisory Board</p>
          </div>

          <div>
            <img src={Staff} alt="Staff members" /> 

            <h3>Staff & Volunteers</h3>

            <p>Our staff is integral to everything we do at The Newberry Institute, shaping our culture, upholding our reputation for excellence and ensuring success in our mission. We thank them.</p>

            <p onClick={()=>{
              Navigate(`/team-type/staff`)
            }}>Meet our Staff and Volunteers</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WhoWeAre