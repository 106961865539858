import React, {useState, useEffect} from 'react'
import './TeamType.css'
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';
import BaseUrl from '../../../BaseUrl';

function TeamType() {

  const location = useLocation();
  const teamId = location.pathname.split('/').pop(); 

  const [teamList, setTeamList] = useState([]);

  const CheckType = () => {
    if(teamId === 'director') {
      return 'Board of directors';
    } else if(teamId === 'staff') {
      return 'Staff & volunteers';
    }
    else if(teamId === 'advisory') {
      return 'Advisory board';
    }
  }


  useEffect(() => {

    axiosInstance
      .get('/api/get-all-team')
      .then((response) => {
        setTeamList(response.data.teams); // Assuming your API returns an array of team records
      })
      .catch((error) => {
        console.error('Error fetching team records:', error);
      });

    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  const filterType = () => {
    return teamList.filter((team) => team.type === teamId);
  };

  const filteredTeamData = filterType();

  return (
    <div className='teamTypeContainer'>
      <h2>{CheckType()}</h2>

      {filteredTeamData.length > 0 ? (
        <div className='teamTypeContainerFirst'>
          {filteredTeamData.map((team, index) => (
            <div key={index}>
              <img src={`${BaseUrl}/images/${team.image}`} alt={team.name} />

              <h2>{team.name}</h2>
              <p>{team.workstation} {team.position !== '' ? <span>-</span> : ''} {team.position}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No team data found for the specified team type.</p>
      )}
    </div>
  )
}

export default TeamType
