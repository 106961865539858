import React, {useEffect} from 'react'
import './AllResearches.css'

import Imag from "../../images/Capital Insurrection.jpg"


function AllResearches() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageAllResearches'>
      <div>
        <div>
            <p>The Newberry Institute</p>
            <h1>The Plow and Pew Democracy Center</h1>
            <p>The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research. The Reading Room is a safe space that offers an opportunity for community members to learn and explore the history, religion, peace studies, justice, and education related to the Black community. The Reading Room serves as the intellectual center of gravity of The Newberry Foundation, and it sponsors intellectual programming that covers various aspects of the Black Experience.</p>
            <p>The Reading Room is not only a library. it's also a place where Community Members can gather to discuss, debate, and learn from each other. It is a hub of activity that offers a peaceful and stimulating environment to all visitors. The Reading Room is adjacent to Annie Lee's Porch which adds to the charm of the location. Annie Lee's Porch offers a beautiful view of the surrounding area and provides a cozy and comfortable setting to relax and enjoy a cup of coffee or tea.</p>
        </div>
        <div>
            <div className='huh'>
                <img src={Imag} alt="" />
            </div>
        </div>
      </div>

      <div>
          <div>
            <p>The Newberry Institute</p>
            <h1>The Plow and Pew Democracy Center</h1>
            <p>The Plow & The Pew Reading Room and Democracy Center is a remarkable institution that aims to capture the Black Experience through literature and research. The Reading Room is a safe space that offers an opportunity for community members to learn and explore the history, religion, peace studies, justice, and education related to the Black community. The Reading Room serves as the intellectual center of gravity of The Newberry Foundation, and it sponsors intellectual programming that covers various aspects of the Black Experience.</p>
            <p>The Reading Room is not only a library. it's also a place where Community Members can gather to discuss, debate, and learn from each other. It is a hub of activity that offers a peaceful and stimulating environment to all visitors. The Reading Room is adjacent to Annie Lee's Porch which adds to the charm of the location. Annie Lee's Porch offers a beautiful view of the surrounding area and provides a cozy and comfortable setting to relax and enjoy a cup of coffee or tea.</p>
          </div>
        <div>
            <div className='huh'>
                <img src={Imag} alt="" />
            </div>
        </div>
      </div>

    </div>
  )
}

export default AllResearches
