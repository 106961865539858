import React, { useState, useEffect } from 'react';
import './ElectionCalender.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BaseUrl from '../../../BaseUrl';

function formatDate(dateString) {
  const options = {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
  };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}


function ElectionCalendar() {
  const [eventElection, setEventElection] = useState([]);

  const [latestEventElection, setLatestEventElection] = useState([]);

  const Navigate = useNavigate()


  useEffect(() => {
    axios
    .get(`${BaseUrl}/api/get-all-election`)
    .then((response) => {
      const elections = response.data.elections;

      // Sort elections by date in descending order
      const sortedElection = elections.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Format the events
      const formattedElections = sortedElection.map(event => {
        return {
          id: event.id,
          title: event.title,
          start: event.date, 
          end: event.date,
          tooltip: event.title,
          desc: event.description
        };
      });


      setEventElection(formattedElections);
    })
    .catch((error) => {
      console.error('Error fetching research records:', error);
    });



      axios
      .get(`${BaseUrl}/api/get-all-election`)
      .then((response) => {
        const elections = response.data.elections;

        if (elections.length > 0) {
          elections.sort((a, b) => new Date(a.date) - new Date(b.date));
          const latestElection = elections[0];

          const formattedElection = {
            id: latestElection.id,
            title: latestElection.title,
            start: latestElection.date,
            end: latestElection.date,
            tooltip: latestElection.title,
            desc: latestElection.description
          };


          

          setLatestEventElection([formattedElection]);
        } else {
          setLatestEventElection([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });

    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='landingBoxRestOfBox'>


      <div className='ameenah'>
        <div></div>
        <div>
            <h1>ELECTION CALENDAR</h1>
        </div>
        <div></div>
      </div>
    

      <div className='four'>


        <div>
          
          <div>
            {latestEventElection && latestEventElection.slice(0, 1).map((object, index)=>(
              <div key={index} onClick={()=>{
                Navigate(`/election/${object.id}?slug=${encodeURIComponent(object.title).replace(/%20/g, '-')}`)
              }}>
                <div>
                  <h4 style={{color: '#f5ffff'}}>{formatDate(object.start)}</h4>
                </div>
                <div>
                  <p>
                    {object.desc.length >100
                        ? object.desc.slice(0, 100) + '...'
                        : object.desc}
                  </p>
                  <h4 style={{color: '#f5ffff'}}>
                    {object.title.length > 100
                        ? object.title.slice(0, 100) + '...'
                        : object.title}
                  </h4>
                  <p>{object.date}</p>
                </div>
              </div>
            ))}
            

            <div>
            {eventElection && eventElection.slice(1).map((object, index) => (
                <div key={index} onClick={() => {
                  Navigate(`/election/${object.id}?slug=${encodeURIComponent(object.title).replace(/%20/g, '-')}`)
                }}>
                  <div>
                    <h4 style={{color: '#f5ffff'}}>{formatDate(object.start)}</h4>
                  </div>
                  <div>
                    <p>
                      {object.desc.length > 100
                          ? object.desc.slice(0, 100) + '...'
                          : object.desc}
                    </p>
                    <h4 style={{color: '#f5ffff'}}>
                      {object.title.length > 100
                          ? object.title.slice(0, 100) + '...'
                          : object.title}
                    </h4>
                    <p>{object.date}</p>
                  </div>
                </div>
            ))}
              
            </div>
          </div>
          
        </div>
      </div>


    </div>
  )
}

export default ElectionCalendar