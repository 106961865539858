import React, {useEffect} from 'react'
import './Research.css'
import Imag from '../../images/annie.png'
import ResearchDivision from './ResearchDivision';

function Research() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  
  return (
    <div className='landingPageResearch'>
      <div className='first'>
        <img src={Imag} alt="" />
      </div>

      <div className='second'>
        <p>Step into history at Annie Lee's Porch Southern Heritage Center where The Newberry Institute's preservation programming comes to life. Celebrating diversity American history and empowering participants we're preserving the past while shaping a brighter future. Join us on this enriching journey.</p>
      </div>

      <ResearchDivision />
    </div>
  )
}

export default Research
