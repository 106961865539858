import React, { useState } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import './Donation.css'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function CreateDonation({ handleToggle, togActive }) {

  const Navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    amount: '',
    date: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Send a POST request to create a donation
    axiosInstance
      .post(`/api/add-donation`, formData)
      .then(response => {
        Navigate('/dashboard/donations');
        // Handle success (e.g., show a success message)
      })
      .catch(error => {
        console.error('Error creating donation:', error);
        // Handle error (e.g., show an error message)
      });
  };
  

  return (
    <div className="container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='donationForm'>
          <h1>Create Donation</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Email:</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Amount:</label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Date:</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateDonation;
