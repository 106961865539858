import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import axiosInstance from '../../../axiosInstance';

function EditGallery() {
  const Navigate = useNavigate();

  const location = useLocation();
  const galleryId = location.pathname.split('/').pop();

  const [formData, setFormData] = useState({
    image: null,
    title: '',
    slug: '',
  });

  useEffect(() => {
    axiosInstance
      .get(`/api/get-single-gallery/${galleryId}`)
      .then((response) => {
        const galleryData = response.data.gallery_item;
        setFormData(galleryData);
      })
      .catch((error) => {
        console.error('Error fetching gallery data:', error);
      });
  }, [galleryId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('slug', formData.slug);
  
    // Send the form data using Axios
    axiosInstance
      .post(`/api/update-single-gallery/${galleryId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the correct content type for FormData
        },
      })
      .then((response) => {
        Navigate('/dashboard/galleries');
      })
      .catch((error) => {
        console.error('Error updating gallery:', error);
      });
  };
  

  return (
    <div className="container">
      <div className="main">
        <DashboardTopBar />

        <div className="galleryForm">
          <form onSubmit={handleSubmit}>
            <h1>Edit Gallery</h1>
            <div>
              <label>Image:</label>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
            </div>
            <div>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Slug:</label>
              <input
                type="text"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Update Gallery</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditGallery;
