import React, {useEffect} from 'react'
import './Education.css'
import Imag from '../../images/plow.png'


import EducationDivision from './EducationDivision'

function Education() {
  

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  
  return (
    <div className='landingPageEducation'>
      <div className='one'>
        <img src={Imag} alt="" />
      </div>


      <div className='two'>
        <div></div>
        <div>
            <h1>The Plow & Pew Democracy Center</h1>
        </div>
        <div></div>
      </div>

      <div className='three'>
        <h4 style={{color: '#113756'}}>
          <p>
            The Democracy Center is co-located with Rosa's Food Pantry. It serves as remarkable hub of knowledge and empowerment. The Center proudly holds the largest collection of books on the African American experience in middle Georgia, as well as an extensive assortment of piano rolls and a rare collection of out-of-print political journals.
          </p>
          <br></br>
          <p>
           Additionally, it houses the complete collection of the official records of the Civil War. Within the Center are computer access boasting over 5 terabytes of Democracy-related photos, documents and speeches, which serves as a valuable resource. Furthermore, the center doubles as an event space, hosting speakers, academics, and community activists. Discover the power of knowledge and democracy at The Democracy Center.
        </p>
        </h4>
        
      </div>


      <EducationDivision />
    </div>
  )
}

export default Education
