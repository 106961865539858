import axios from 'axios';

const BaseUrl = 'https://newberry.wakazi.co.ke';

const axiosInstance = axios.create({
  baseURL: BaseUrl,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;
