import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './Donation.css'
import axiosInstance from '../../../axiosInstance';

function ShowDonation({ handleToggle, togActive }) {
  const [donation, setDonation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    axiosInstance.get(`${BaseUrl}/api/get-single-donation/${id}`)
      .then(response => {
        setDonation(response.data.donation);
      })
      .catch(error => {
        console.error('Error fetching donation:', error);
      });
  }, [location]);

  return (
    <div className="container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='donationShowDash'>
          
          {donation ? (
            <div>
              <h1>Donation Details</h1>
              <p>Email: {donation.email}</p>
              <p>Amount: {donation.amount}</p>
              {/* Add more donation details here */}
            </div>
          ) : (
            <p>Loading donation details...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowDonation;
