import React, { useState } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { useNavigate } from 'react-router-dom';
import './Research.css'
import axiosInstance from '../../../axiosInstance';

function CreateActionNetwork({ handleToggle, togActive }) {
  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    image: null,
    title: '',
    description: '',
    link: '',
    slug: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('link', formData.link);
    formDataToSend.append('slug', formData.slug);
  
    // Send the form data using custom Axios instance
    axiosInstance
      .post('/api/add-action-network', formDataToSend)
      .then((response) => {
        // Handle success, e.g., redirect to a success page or show a success message
        Navigate('/dashboard/action-networks');
      })
      .catch((error) => {
        // Handle error, e.g., show an error message to the user
        console.error('Error creating actionNetwork:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchForm'>
          <form onSubmit={handleSubmit}>
            <h1>Create action network</h1>
            <div>
              <label>Image:</label>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
            </div>
            <div>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Slug:</label>
              <input
                type="text"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Link:</label>
              <input
                type="url"
                name="link"
                value={formData.link}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className='textAreaClass'
              />
            </div>
            <button type="submit">Create action network</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateActionNetwork;
