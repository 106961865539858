import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import axiosInstance from '../../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import './Research.css'

function EditPodcast({ handleToggle, togActive }) {
  const Navigate = useNavigate()
  const location = useLocation();
  const podcastId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [formData, setFormData] = useState({
    image: null,
    title: '',
    link: '',
    description: '',
    slug: '',
  });

  useEffect(() => {
    // Fetch existing research data based on the podcastId
    axiosInstance
      .get(`/api/get-single-podcast/${podcastId}`)
      .then((response) => {
        const researchData = response.data.podcast; // Assuming your API returns research data in a specific format
        setFormData(researchData);
      })
      .catch((error) => {
        console.error('Error fetching research data:', error);
      });
  }, [podcastId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image); // Ensure image is appended as a file
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('link', formData.link);
    formDataToSend.append('slug', formData.slug);
  
    // Update research data using custom Axios instance
    axiosInstance
      .post(`/api/update-single-podcast/${podcastId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the correct content type for FormData
        },
      })
      .then((response) => {
        Navigate('/dashboard/podcasts');
      })
      .catch((error) => {
        console.error('Error updating research:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchForm'>
          <h1>Edit Podcast</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Image:</label>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
            </div>
            <div>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Slug:</label>
              <input
                type="text"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Link:</label>
              <input
                type="url"
                name="link"
                value={formData.link}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className='textAreaClass'
              />
            </div>
            <button type="submit">Update Research</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditPodcast;
