import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './Team.css'
import axiosInstance from '../../../axiosInstance';

function ShowTeam({ handleToggle, togActive }) {
  const location = useLocation();
  const teamId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch team data based on the teamId
    axiosInstance
      .get(`/api/get-single-team/${teamId}`)
      .then((response) => {
        const teamData = response.data.team;
        setTeam(teamData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching team data:', error);
        setError('An error occurred while fetching the team data.');
        setLoading(false);
      });
  }, [teamId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='teamShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {team && (
            <div>
              <h1>Team member</h1>
              <div>
                <strong>Name:</strong> {team.name}
              </div>
              <div>
                <strong>Team type:</strong> {team.type}
              </div>

              <div>
                <strong>Position:</strong> {team.position}
              </div>
              <div>
                <strong>Workstation:</strong> {team.workstation}
              </div>

              {team.image && (
                <div>
                  <strong>Image:</strong>
                  <img
                    src={`${BaseUrl}/images/${team.image}`}
                    alt="team pic"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  />
                </div>
              )}
              <div>
                {/* You can display other team details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowTeam
