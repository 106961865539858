import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexResearch({ handleToggle, togActive }) {
  const [researchList, setResearchList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch research records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-story')
      .then((response) => {
        setResearchList(response.data.stories); // Assuming your API returns an array of research records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
        setLoading(false);
      });
  }, []);



  const handleDelete = (researchId) => {
    // Send a DELETE request to the API to delete the research record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-story/${researchId}`)
      .then(() => {
        // Remove the deleted research record from the state
        setResearchList((prevResearchList) =>
          prevResearchList.filter((research) => research.id !== researchId)
        );
        console.log('Research record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting research record:', error);
      });
  };

  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="details">
          <div className="recentOrders">
            <div className="cardHeader">
              <h2>Researches</h2>
              <Link to="/dashboard/create-research">Create research</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  researchList.map((research) => (
                    <tr key={research.id}>
                      <td>{research.title}</td>
                      <td>{research.subtitle}</td>
                      <td>
                        <Link to={`/dashboard/show-research/${research.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-research/${research.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(research.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexResearch;
