import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './User.css'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function Edit({ handleToggle, togActive }) {

  const Navigate = useNavigate()
  
  const location = useLocation();
  const userId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    // Fetch existing user data based on the userId
    axiosInstance
      .get(`${BaseUrl}/api/get-single-user/${userId}`)
      .then((response) => {
        const userData = response.data.user; // Assuming your API returns user data in a specific format
        setFormData(userData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('username', formData.username);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);

    // Update user data using Axios from axiosInstance
    axiosInstance
      .put(`${BaseUrl}/api/update-single-user/${userId}`, formDataToSend)
      .then((response) => {
        // Handle success, e.g., redirect to a success page or show a success message
        Navigate('/dashboard/users');
      })
      .catch((error) => {
        // Handle error, e.g., show an error message to the user
        console.error('Error updating user:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='createForm'>
        
          <form onSubmit={handleSubmit}>
          <h1>Edit User</h1>
            <div>
              <label>Username:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"  // Corrected to match the key in formData
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Update Research</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Edit;
