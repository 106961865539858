import React from 'react';
import './App.css';

function Vote() {

  const handleChange = (event) => {
    const value = event.target.value;
    if (value) {
      window.open(value, '_blank'); 
    }
  };

  return (
    <div className="page-template page-template-page-templates page-template-fullwidthpage page-template-page-templatesfullwidthpage-php page page-id-23911 wp-custom-logo wp-embed-responsive group-blog" itemscope itemtype="http://schema.org/WebSite">
      <div className="site" id="page">
        <div className="wrapper" id="full-width-page-wrapper">
          <div className="containerr" id="content">
            <div className="row">
              <div className="col-md-12 content-area" id="primary">
                <main className="site-main" id="main" role="main">
                  <article className="post-23911 page type-page status-publish hentry" id="post-23911">
                    <header className="entry-header">
                      <h1 className="entry-title">Register to Vote/Check Registration</h1>
                    </header>
                    <div className="entry-content">
                      <p>If you’re looking to register to vote or check/update your voter registration, you’re in the right place! Not sure if you need to update your registration? Here’s a list of reasons to check your status:</p>
                      <ul className="wp-block-list">
                        <li><span>You’ve moved</span></li>
                        <li><span>You’ve changed your name since you last voted</span></li>
                        <li><span>You got married</span></li>
                      </ul>
                      <p>Here you can quickly and easily get registered to vote. Simply select your state from the dropdown menu below and you will automatically be redirected to a menu of official voter registration tools for your state on Vote.gov.  </p>
                      <div style={{ height: '36px' }} aria-hidden="true" className="wp-block-spacer"></div>
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <div className="select">
                        <select aria-label="Select a State" className="state-select" name="state-select" data-id="state-select" onChange={handleChange}>
                          <option value="">Select a State</option>
                          <option value="https://vote.gov/register/al/">Alabama</option>
                          <option value="https://vote.gov/register/ak/">Alaska</option>
                          <option value="https://vote.gov/register/az/">Arizona</option>
                          <option value="https://vote.gov/register/ar/">Arkansas</option>
                          <option value="https://vote.gov/register/ca/">California</option>
                          <option value="https://vote.gov/register/co/">Colorado</option>
                          <option value="https://vote.gov/register/ct/">Connecticut</option>
                          <option value="https://vote.gov/register/de/">Delaware</option>
                          <option value="https://vote.gov/register/dc/">District of Columbia</option>
                          <option value="https://vote.gov/register/fl/">Florida</option>
                          <option value="https://vote.gov/register/ga/">Georgia</option>
                          <option value="https://vote.gov/register/hi/">Hawaii</option>
                          <option value="https://vote.gov/register/id/">Idaho</option>
                          <option value="https://vote.gov/register/il/">Illinois</option>
                          <option value="https://vote.gov/register/in/">Indiana</option>
                          <option value="https://vote.gov/register/ia/">Iowa</option>
                          <option value="https://vote.gov/register/ks/">Kansas</option>
                          <option value="https://vote.gov/register/ky/">Kentucky</option>
                          <option value="https://vote.gov/register/la/">Louisiana</option>
                          <option value="https://vote.gov/register/me/">Maine</option>
                          <option value="https://vote.gov/register/md/">Maryland</option>
                          <option value="https://vote.gov/register/ma/">Massachusetts</option>
                          <option value="https://vote.gov/register/mi/">Michigan</option>
                          <option value="https://vote.gov/register/mn/">Minnesota</option>
                          <option value="https://vote.gov/register/ms/">Mississippi</option>
                          <option value="https://vote.gov/register/mo/">Missouri</option>
                          <option value="https://vote.gov/register/mt/">Montana</option>
                          <option value="https://vote.gov/register/ne/">Nebraska</option>
                          <option value="https://vote.gov/register/nv/">Nevada</option>
                          <option value="https://vote.gov/register/nh/">New Hampshire</option>
                          <option value="https://vote.gov/register/nj/">New Jersey</option>
                          <option value="https://vote.gov/register/nm/">New Mexico</option>
                          <option value="https://vote.gov/register/ny/">New York</option>
                          <option value="https://vote.gov/register/nc/">North Carolina</option>
                          <option value="https://vote.gov/register/nd/">North Dakota</option>
                          <option value="https://vote.gov/register/oh/">Ohio</option>
                          <option value="https://vote.gov/register/ok/">Oklahoma</option>
                          <option value="https://vote.gov/register/or/">Oregon</option>
                          <option value="https://vote.gov/register/pa/">Pennsylvania</option>
                          <option value="https://vote.gov/register/ri/">Rhode Island</option>
                          <option value="https://vote.gov/register/sc/">South Carolina</option>
                          <option value="https://vote.gov/register/sd/">South Dakota</option>
                          <option value="https://vote.gov/register/tn/">Tennessee</option>
                          <option value="https://vote.gov/register/tx/">Texas</option>
                          <option value="https://vote.gov/register/ut/">Utah</option>
                          <option value="https://vote.gov/register/vt/">Vermont</option>
                          <option value="https://vote.gov/register/va/">Virginia</option>
                          <option value="https://vote.gov/register/wa/">Washington</option>
                          <option value="https://vote.gov/register/wv/">West Virginia</option>
                          <option value="https://vote.gov/register/wi/">Wisconsin</option>
                          <option value="https://vote.gov/register/wy/">Wyoming</option>
                        </select>
                      </div>
                      <div style={{ height: '36px' }} aria-hidden="true" className="wp-block-spacer"></div>
                      <div style={{ height: '0px' }} aria-hidden="true" className="wp-block-spacer"></div>
                      <p><em><strong>Note:</strong> If your state has online voter registration, you will be prompted to click the &#8220;Start your online registration on (STATE)&#8217;s election website&#8221; link under the &#8220;How to register to vote heading.&#8221;</em></p>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vote;
