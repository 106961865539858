import React, { useState, useEffect } from 'react';
import './Election.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BaseUrl from '../../../BaseUrl';

function formatDate(dateString) {
  const options = {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
  };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

function Election() {
  const Navigate = useNavigate();
  const { id } = useParams();

  const [eventElection, setEventElection] = useState([]);
  const [image, setImage] = useState(null)

  const [election, setElection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch election data based on the id
    axios
      .get(`${BaseUrl}/api/get-single-election/${id}`)
      .then((response) => {
       
        const electionData = response.data.election; // Assuming your API returns event data in a specific format
        setElection(electionData);
        setImage(`${BaseUrl}/images/${electionData.image}`)
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching election data:', error);
        setError('An error occurred while fetching the election data.');
        setLoading(false);
      });



      axios
      .get(`${BaseUrl}/api/get-all-election`)
      .then((response) => {
        setEventElection(response.data.elections);
      })
      .catch((error) => {
        console.error('Error fetching election records:', error);
      });

      window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className='landingPageTroubleElection'>
      <div>
        <div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {election && (
          <div>
            <h2>{election.title}</h2>
            <p>{election.description}</p>
            <p>{formatDate(election.date)}</p>
          </div>
          )}
        </div>

        <div className='huh'>
          <img src={image} alt='' />
        </div>
      </div>


      <div className='child'>
        {eventElection &&
          eventElection.slice(1).map((object, index) => (
            <div key={index} onClick={() => {
              Navigate(`/election/${object.id}?slug=${encodeURIComponent(object.title).replace(/%20/g, '-')}`);
              window.scrollTo(0, 0);
            }}>
              <div>
                <img src={`${BaseUrl}/images/${object.image}`} alt='' />
              </div>

              <div>
                <h4 style={{color: '#f5ffff'}}>
                  {object.title.length > 100
                    ? object.title.slice(0, 100) + '...'
                    : object.title}
                </h4>
                <p>
                  {object.description.length > 100
                    ? object.description.slice(0, 100) + '...'
                    : object.description}
                </p>
                <p>{formatDate(object.date)}</p>
              </div>
            </div>
          ))}
      </div>

      
    </div>
  );
}

export default Election;