import React, { useState } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { useNavigate } from 'react-router-dom';
import './Research.css'
import axiosInstance from '../../../axiosInstance';

function CreateArticle({ handleToggle, togActive }) {
  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    pdf: null,
    title: '',
    body: '',
    slug: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePdfChange = (e) => {
    setFormData({ ...formData, pdf: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('pdf', formData.pdf);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('body', formData.body);
    formDataToSend.append('slug', formData.slug);
  
    // Send the form data using custom Axios instance
    axiosInstance
      .post('/api/add-article', formDataToSend)
      .then((response) => {
        // Handle success, e.g., redirect to a success page or show a success message
        Navigate('/dashboard/articles');
      })
      .catch((error) => {
        // Handle error, e.g., show an error message to the user
        console.error('Error creating article:', error);
      });
  };

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchForm'>
          <form onSubmit={handleSubmit}>
            <h1>Create Article</h1>
            <div>
              <label>Upload pdf:</label>
              <input
                type="file"
                name="pdf"
                onChange={handlePdfChange}
              />
            </div>

            <div>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Slug:</label>
              <input
                type="text"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
              />
            </div>


            <div>
              <label>Body:</label>
              <textarea
                name="body"
                value={formData.body}
                onChange={handleChange}
                className='textAreaClass'
              />
            </div>
            <button type="submit">Create Article</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateArticle;
