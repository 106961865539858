import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import axiosInstance from '../../../axiosInstance';

function ShowArticle({ handleToggle, togActive }) {
  const location = useLocation();
  const articleId = location.pathname.split('/').pop(); // Extracting the ID from the URL

  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch article data based on the articleId using custom Axios instance
    axiosInstance
      .get(`/api/get-single-article/${articleId}`)
      .then((response) => {
        const articleData = response.data.article; // Assuming your API returns article data in a specific format
        setArticle(articleData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching article data:', error);
        setError('An error occurred while fetching the article data.');
        setLoading(false);
      });
  }, [articleId]);
  

  return (
    <div className="container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='researchShowDash'>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {article && (
            <div>
              <h1>Article</h1>
              <div>
                <strong>Title:</strong> {article.title}
              </div>
              <div>
                <strong>Slug:</strong> {article.slug}
              </div>
              <div>
                <strong>Body:</strong> {article.body}
              </div>

              {article.pdf && (
                  <div>
                    <strong>PDF:</strong>
                    <iframe
                      src={`${BaseUrl}/pdfs/${article.pdf}`}
                      title="Article PDF"
                      style={{ width: '100%', height: '500px', border: 'none' }}
                    />
                  </div>
              )}
              <div>
                {/* You can display other article details here */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowArticle;
