import React, { useEffect, useState } from 'react';
import './AllPodcasts.css';
import BaseUrl from '../../../BaseUrl';
import axios from 'axios';


function AllPodcasts() {

  const [podcastList, setPodcastList] = useState([]);

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/get-all-podcast`)
      .then((response) => {
        const formattedPodcasts = response.data.podcasts.map(podcast => {
          return {
            ...podcast
          };
        });
        setPodcastList(formattedPodcasts);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='landingPageAllPodcasts'>
      {podcastList && podcastList.map((object, index) => (
        <div key={index}>
          <div>
            <h1>{object.title}</h1>
            <p>{object.description}</p>
            <div>
                <strong>Link:</strong> <a href={object.link} target="_blank" rel="noopener noreferrer">Visit podcast</a>
              </div>
          </div>
          <div>
            <div className='huh'>
              <img src={`${BaseUrl}/images/${object.image}`} alt='' />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AllPodcasts;
