import React from 'react'
import './TopNavbar.css'
import {useNavigate} from 'react-router-dom'
import { Person2Outlined } from '@mui/icons-material'

function TopNavbar() {
  const Navigate = useNavigate()

  const token = localStorage.getItem('token');
  return (
    <div className='landingTopNavbarContainer'>
      <ul className='landingLinks'>
          <li onClick={()=>{
            Navigate('/the-plow-and-pew-democracy-center')
          }}>THE PLOW AND PEW DEMOCRACY CENTER </li>
          <li onClick={()=>{
            Navigate(`/rosa's-food-pantry`)
          }}>ROSA’S FOOD PANTRY </li>
            <li onClick={()=>{
            Navigate(`/aniee-lee's-porch`)
          }}>ANIEE LEE’S PORCH </li>
          <li onClick={()=>{
            Navigate(`/ameenah's-interfaith-center`)
          }}>AMEENAH’S INTERFAITH CENTER</li>

          {token === null ? 
          null
          :
          <li onClick={()=>{
            Navigate('/dashboard')
          }}><Person2Outlined /></li>
          }
          
        </ul>
    </div>
  )
}

export default TopNavbar
