import React, {useEffect} from 'react'
import './Community.css'
import Imag from '../../images/rosa.jpg'
import CommunityDivision from './CommunityDivision';
import MrRosa from '../../images/mrrosa.jpeg'

function Community() {
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className='landingPageCommunity'>
      <div className='one'>
        <img src={Imag} alt="" />
      </div>

      <div className='two'>
        <div></div>
        <div>
            <h1>Rosa's Food Pantry</h1>
        </div>
        <div></div>
      </div>

      <div className='mrRosaImage'>
        <img src={MrRosa} alt='' />
      </div>

      <div className='three'>
        <p>
        Rosa's Food Pantry is committed to providing access to nutritious food and empowering the community with the knowledge and resources they need to make healthy choices.
        </p>
      </div>

      <CommunityDivision />
    </div>
  )
}

export default Community
