import React from 'react'
import './MiddleNavbar.css'
import { Button } from '@mui/material'
import Logo from '../../../images/tn1.png';
import { useNavigate } from 'react-router-dom';
import { Facebook, Instagram, LinkedIn, Menu, Twitter } from '@mui/icons-material';

function MiddleNavbar({ handleToggle2 }) {

  const Navigate = useNavigate()
  
  return (
    <div className='MiddleNavbarContainer'>
      <div className='left'>
        <div className='leftLogoInitial'>
          <div className='logo'>
            <img src={Logo} alt="" onClick={() => Navigate('/')} />
            <Menu className='rightMenu' onClick={handleToggle2} />
          </div>

          <div className='fullName' onClick={() => Navigate('/')}>
            <h2>The Newberry Institute</h2>
          </div>
        </div>

        <div className='tagline'>
          <p>Strengthening democracy in rural communities</p>
        </div>
      </div>

      <div className='right'>
        <Button onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
        }}>Donate</Button>

        <div className='socialIcons'>
          <Facebook className='icon' onClick={()=>{
            window.open('https://www.facebook.com/newberryInstitute', '_blank');
        }} />
          <LinkedIn className='icon' onClick={()=>{
            window.open('https://www.linkedin.com/company/thenewberryinstitute', '_blank');
        }} />
          <Twitter className='icon' onClick={()=>{
            window.open('https://twitter.com/NewberryInst', '_blank');
        }} />
          <Instagram className='icon' onClick={()=>{
            window.open('https://www.instagram.com/thenewberryinstitute', '_blank');
        }} />
        </div>
      </div>


    </div>
  )
}

export default MiddleNavbar
