import React, { useEffect, useState } from 'react';
import './Stories.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import BaseUrl from '../../../BaseUrl';

function formatDate(dateString) {
  const options = { weekday: 'long', day: 'numeric', year: 'numeric', month: 'long' };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

function Articles() {
  const Navigate = useNavigate();
  const { id } = useParams();

  const [researchList, setResearchList] = useState([]);
  const [pdf, setPdf] = useState(null)

  const [isBodyExpanded, setIsBodyExpanded] = useState(false);
  const [research, setResearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch research data based on the id
    axios
      .get(`${BaseUrl}/api/get-single-article/${id}`)
      .then((response) => {
       
        const researchData = response.data.article; // Assuming your API returns research data in a specific format
        setResearch(researchData);
        setPdf(`${BaseUrl}/pdfs/${researchData.pdf}`)
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching research data:', error);
        setError('An error occurred while fetching the research data.');
        setLoading(false);
      });



      axios
      .get(`${BaseUrl}/api/get-all-article`)
      .then((response) => {
        const formattedArticles = response?.data?.articles?.map(article => {
          return {
            ...article,
            formattedDate: formatDate(article.created_at) // Add a formattedDate property
          };
        });
        setResearchList(formattedArticles);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });

      window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className='landingPageTroubleStories'>
      <div>
        <div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {research && (
          <div>
            <p>{research.title}</p>
            <p>{formatDate(research.created_at)}</p>
            {isBodyExpanded ? (
              <p className='lp-p whitespace-pre-line'>{research.body}</p>
            ) : (
              <p>
                {research.body.length > 1000
                  ? research.body.slice(0, 1000) + '...'
                  : research.body}
              </p>
            )}
            {research.body.length > 600 && !isBodyExpanded && (
              <Button onClick={() => setIsBodyExpanded(true)} className='readButton'>Read More</Button>
            )}
          </div>
        )}
        </div>

        <div className='huh huho'>
          {pdf &&
                <iframe
                    src={pdf}
                    title="Article PDF"
                    style={{ width: '100%', height: '350px', border: 'none' }}
                  />
                }
        </div>
      </div>


      <div className='child lp-p'>
        {researchList && researchList.map((object, index) => (
            <div key={index} onClick={() => {
                    Navigate(`/article/${object.id}`);
                    window.scrollTo(0, 0);
                    setIsBodyExpanded(false)
                  }}>
              <div>
                {object.pdf &&
                <iframe
                    src={`${BaseUrl}/pdfs/${object.pdf}`} 
                    title="Article PDF"
                    className='iframet'
                    style={{ width: '100%', border: 'none' }}
                  />
                }
              </div>

              <div>
                <h4 className='whitespace-pre-line'>{object.title}</h4>
                <p>{object.formattedDate}</p>
              </div>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default Articles;
