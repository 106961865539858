import React, { useState, useEffect } from 'react';
import './SliderCarousel.css';

import Image1 from '../images/ameenah/one.jpeg';

// import { ArrowRight } from '@mui/icons-material';


const images = [
  {
    'id': 1,
    'image': Image1,
    'description': `This is going to be a game-changer for our community, bringing cutting-edge technology, innovative solutions and endless opportunities right to our doorstep. Stay tuned for more details as we gear up to launch. Big things are on the horizon and we can't wait to share this journey with you. Keep an eye out for updates.`
  },
];

const SliderCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);



  // const goTo = () => {
  //   if (isPaused) {
  //     setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  //   }
  // };

  useEffect(() => {
    const nextSlide = () => {
      if (!isPaused) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      }
    };
    
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 30000); // Change to 20000 for 20 seconds as per your original code

    return () => {
      clearInterval(autoSlideInterval);
    };
  }, [isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div className="banner-sliders-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="sliders">
        <div
          className="sliders-wrapper"
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {images.map((object, index) => (
            <div key={index} className="slidem">
              <img src={object.image} alt={`Slidem ${index}`} />
              <div className='descriptionHolder'>
                <p>{object.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <button className="next-button" onClick={goTo}>
        <ArrowRight />
      </button> */}
    </div>
  );
};

export default SliderCarousel;
